import { captureException } from '@sentry/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import {
  WidgetLayoutType,
  WidgetTemplate,
} from 'common/components/RCMWidget/typings';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectIsMobile,
  selectRcmId,
  selectRecommendBlockID,
  selectRuid,
  selectUserId,
} from 'common/redux/runtime/selectors';
import { selectItemExcludedIds } from 'common/redux/selectors';
import { PuidsType } from 'config/constants/common';
import { APP_TYPE } from 'config/constants/devices';
import {
  BLOCK_ID_FOR_RESOURCES,
  BLOCK_ID_FOR_RESOURCES_REDESIGN,
  RESOURCES_WITH_RNET,
} from 'config/constants/resourceRnet';
import { useAppSelector } from 'store/hooks';
import { IInitBlockResult } from 'typings/rcmw';

import { RCM_BLOCK_TYPE } from './useRcm';

type GetRcmBlockIdByResourceIdPropsType = {
  resourceId?: RESOURCES_WITH_RNET;
  isMobile: boolean;
  forceRedesign: boolean;
};

/**
 * Функция получения blockId по resourceId
 * @param resourceId - id источника кластера;
 * @param isMobile - флаг мобильной версии;
 * @param forceRedesign - флаг редизайна;
 */
export const getRcmBlockIdByResourceId = ({
  resourceId,
  isMobile,
  forceRedesign,
}: GetRcmBlockIdByResourceIdPropsType) => {
  const device = isMobile ? APP_TYPE.mobile : APP_TYPE.desktop;

  if (forceRedesign) {
    return resourceId
      ? BLOCK_ID_FOR_RESOURCES_REDESIGN[resourceId]?.[device]
      : '';
  }

  return resourceId ? BLOCK_ID_FOR_RESOURCES[resourceId]?.[device] : '';
};

/**
 * Добавление функции в очередь виджета рекомендов
 * @param callback - добавляемая функция
 */
const addCommand = (callback: () => void) => {
  window.rcmw.cmd.push(callback);
};

type UseRcmWidgetType = ({
  rcmBlockType,
  resourceId,
  templateName,
  layoutType,
  clusterId,
  puids,
}: {
  rcmBlockType?: RCM_BLOCK_TYPE;
  resourceId?: RESOURCES_WITH_RNET;
  templateName: WidgetTemplate;
  layoutType: WidgetLayoutType;
  clusterId?: ClusterData['id'] | null;
  puids: PuidsType;
}) => React.MutableRefObject<HTMLDivElement | null>;

/**
 * Хук инициализации виджета рекомендов
 * @see https://recsys.pages.rambler-co.ru/recommender/docs/index.html#/widget/integration
 * @param rcmBlockType - алиас, под которым хранится blockId в сторе;
 * @param resourceId - id источника кластера;
 * @param templateName - название шаблона;
 * @param layoutType - тип виджета;
 * @param clusterId - id кластера, для которого получаем рекомменды;
 * @param puids - рекламные пуиды.
 */
export const useRcmWidget: UseRcmWidgetType = ({
  rcmBlockType,
  resourceId,
  templateName,
  layoutType,
  clusterId,
  puids,
}) => {
  const isMobile = useAppSelector(selectIsMobile);
  const rcmId = useAppSelector(selectRcmId);
  const ruid = useAppSelector(selectRuid);
  const userId = useAppSelector(selectUserId);
  const itemExcludedIds = useAppSelector(selectItemExcludedIds, shallowEqual);
  const rcmBlockId = useAppSelector(selectRecommendBlockID(rcmBlockType));
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const rcmBlockRef = useRef<HTMLDivElement>(null);

  const rcmBlockIdByResourceId = getRcmBlockIdByResourceId({
    resourceId,
    isMobile,
    forceRedesign,
  });

  const blockId = useMemo(
    () => rcmBlockId || rcmBlockIdByResourceId,
    [rcmBlockId, rcmBlockIdByResourceId],
  );

  useEffect(() => {
    if (!rcmBlockRef.current) return;

    if (!window.rcmw) {
      // Добавление скрипта
      window.rcmw = window.rcmw || { cmd: [] };

      const script = document.createElement('script');

      script.async = true;
      script.src = 'https://rcmjs.rambler.ru/static/rcmw/rcmw.js';

      document.head.appendChild(script);
    }

    const settings: Rcmw.IUserBlockSettings = {
      rcmId,
      blockId,
      templateName: templateName as unknown as Rcmw.Template,
      layoutType,
      node: rcmBlockRef.current,
      userId,
      xuid: ruid,
      contextItemId: clusterId || null,
      itemExcludedIds,
      ads: {
        enabled: true,
        jparams: puids,
      },
    };

    let rcmBlock: IInitBlockResult | undefined;

    // Инициализация блока
    addCommand(() => {
      /**
       * Не исключен шанс того, что нода ещё существует
       *  но в верстке её больше нет - такое может встречаться на хроме.
       * Поэтому не лишним будет проверять, а есть ли нода в DOMе.
       */
      if (!settings.node?.isConnected) return;

      window.rcmw
        .initBlock(settings)
        .then((block) => {
          rcmBlock = block;
        })
        .catch((error) => {
          console.error('🚀 ~ useRcmWidget ~ addCommand:', error);
          captureException(error);
        });
    });

    return () => {
      // Удаление блока
      addCommand(() => {
        if (!rcmBlock) return;

        window.rcmw.createSessionId();
        window.rcmw.removeBlock(rcmBlock);

        rcmBlock = undefined;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcmId, blockId, userId, ruid, clusterId, puids]);

  return rcmBlockRef;
};
