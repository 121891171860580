import cn from 'classnames';
import React, { memo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { useRcmWidget } from 'common/hooks/useRcmWidget';
import { selectPagePuids } from 'common/redux/selectors';
import { RESOURCES_WITH_RNET } from 'config/constants/resourceRnet';

import { WidgetLayoutType, WidgetTemplate } from './typings';

import s from './styles.module.css';

type PropsType = {
  className?: string;
  templateName: WidgetTemplate;
  layoutType: WidgetLayoutType;
  clusterId?: ClusterData['id'] | null;
};

interface WithRcmBlockType extends PropsType {
  rcmBlockType: RCM_BLOCK_TYPE;
  resourceId?: never;
}

interface WithResourceId extends PropsType {
  rcmBlockType?: never;
  resourceId: RESOURCES_WITH_RNET | undefined;
}

type RcmWidgetPropsType = WithRcmBlockType | WithResourceId;

/**
 * Виджет реккомендов.
 * @param className - класс, если нужна обертка вокруг виджета;
 * @param rcmBlockType - алиас, под которым хранится blockId в сторе;
 * @param resourceId - id источника кластера;
 * @param templateName - название шаблона;
 * @param layoutType - тип виджета;
 * @param clusterId - id кластера, для которого получаем рекомменды.
 */
export const RcmWidget = memo(function RcmWidget({
  className,
  rcmBlockType,
  resourceId,
  templateName,
  layoutType,
  clusterId,
}: RcmWidgetPropsType) {
  const puids = useSelector(selectPagePuids, shallowEqual);

  const rcmwContainerRef = useRcmWidget({
    rcmBlockType,
    resourceId,
    templateName,
    layoutType,
    clusterId,
    puids,
  });

  return (
    <div className={cn(s.rcmw, className)}>
      <div ref={rcmwContainerRef} />
    </div>
  );
});
