export enum WidgetTemplate {
  v2 = 'v2',
  v3 = 'v3',
  v4 = 'v4',
  v5 = 'v5',
  smi2 = 'smi2',
  infox = 'infox',
  smi24 = 'smi24',
}

export enum WidgetLayoutTypeV2 {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum WidgetLayoutTypeV3 {
  Horizontal = 'horizontal',
}

export enum WidgetLayoutTypeV4 {
  Vertical = 'vertical',
}

export enum WidgetLayoutTypeV5 {
  Vertical = 'vertical',
}

export enum WidgetLayoutTypeSmi2 {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum WidgetLayoutTypeInfox {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum WidgetLayoutTypeSmi24 {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export type WidgetLayoutType =
  | WidgetLayoutTypeV2
  | WidgetLayoutTypeV3
  | WidgetLayoutTypeV4
  | WidgetLayoutTypeV5
  | WidgetLayoutTypeSmi2
  | WidgetLayoutTypeInfox
  | WidgetLayoutTypeSmi24;
