export enum RESOURCES_WITH_RNET {
  auto = 7513,
  doctor = 7812,
  ferra = 247,
  finance = 7220,
  gazeta1 = 4843,
  gazeta2 = 4869,
  kino = 7802,
  lenta1 = 2447,
  lenta2 = 261,
  moslenta = 6852,
  motor = 3264,
  news = 6848,
  passion = 3199,
  secretmag = 6805,
  sport = 7511,
  travel = 7834,
  weekend = 7582,
  woman = 7512,
}

export const RESOURCES_EXCLUDED_FOR_REDESIGN = [
  RESOURCES_WITH_RNET.auto,
  RESOURCES_WITH_RNET.doctor,
  RESOURCES_WITH_RNET.finance,
  RESOURCES_WITH_RNET.kino,
  RESOURCES_WITH_RNET.news,
  RESOURCES_WITH_RNET.sport,
  RESOURCES_WITH_RNET.travel,
  RESOURCES_WITH_RNET.weekend,
  RESOURCES_WITH_RNET.woman,
];

const GAZETA_BLOCKS = {
  desktop: 1096,
  mobile: 1100,
};

const LENTA_BLOCKS = {
  desktop: 1190,
  mobile: 1191,
};

export const RNET_BLOCK_ID_FOR_RESOURCES = {
  [RESOURCES_WITH_RNET.moslenta]: {
    desktop: 1101,
    mobile: 1102,
  },
  [RESOURCES_WITH_RNET.gazeta1]: GAZETA_BLOCKS,
  [RESOURCES_WITH_RNET.gazeta2]: GAZETA_BLOCKS,
  [RESOURCES_WITH_RNET.woman]: {
    desktop: 1164,
    mobile: 1165,
  },
  [RESOURCES_WITH_RNET.finance]: {
    desktop: 1166,
    mobile: 1167,
  },
  [RESOURCES_WITH_RNET.doctor]: {
    desktop: 1168,
    mobile: 1169,
  },
  [RESOURCES_WITH_RNET.kino]: {
    desktop: 1172,
    mobile: 1173,
  },
  [RESOURCES_WITH_RNET.news]: {
    desktop: 1178,
    mobile: 1179,
  },
  [RESOURCES_WITH_RNET.auto]: {
    desktop: 1170,
    mobile: 1171,
  },
  [RESOURCES_WITH_RNET.travel]: {
    desktop: 1174,
    mobile: 1175,
  },
  [RESOURCES_WITH_RNET.sport]: {
    desktop: 1180,
    mobile: 1171,
  },
  [RESOURCES_WITH_RNET.weekend]: {
    desktop: 1182,
    mobile: 1183,
  },
  [RESOURCES_WITH_RNET.motor]: {
    desktop: 1188,
    mobile: 1189,
  },
  [RESOURCES_WITH_RNET.lenta1]: LENTA_BLOCKS,
  [RESOURCES_WITH_RNET.lenta2]: LENTA_BLOCKS,
  [RESOURCES_WITH_RNET.ferra]: {
    desktop: 1192,
    mobile: 1193,
  },
  [RESOURCES_WITH_RNET.secretmag]: {
    desktop: 1194,
    mobile: 1195,
  },
  [RESOURCES_WITH_RNET.passion]: {
    desktop: 1196,
    mobile: 1197,
  },
};

export const BLOCK_ID_FOR_RESOURCES: {
  [key in RESOURCES_WITH_RNET]: {
    desktop: string;
    mobile: string;
  };
} = {
  [RESOURCES_WITH_RNET.auto]: {
    desktop: '3187499f84a541ea8857df111fc176db',
    mobile: 'd7e8c7387ca347b999013f24c45e57c3',
  },
  [RESOURCES_WITH_RNET.doctor]: {
    desktop: '7408b7001c784fcfaa42f9ff669b7413',
    mobile: 'c18b95640c064c5db39a09b0e2c8f29a',
  },
  [RESOURCES_WITH_RNET.ferra]: {
    desktop: '383b9608674b4857bd09e57209a27e1d',
    mobile: '2223742d8706435b886e4770e0e168d0',
  },
  [RESOURCES_WITH_RNET.finance]: {
    desktop: '4a74994c794640b08de649d519e9b495',
    mobile: 'bbb2f30712dc42efa7d1a36d9e6aee17',
  },
  [RESOURCES_WITH_RNET.gazeta1]: {
    desktop: '8521407291d1439aa8ba20a1421cf3ca',
    mobile: 'd3de11bc199f4faabedff128fc9a340e',
  },
  [RESOURCES_WITH_RNET.gazeta2]: {
    desktop: '8521407291d1439aa8ba20a1421cf3ca',
    mobile: 'd3de11bc199f4faabedff128fc9a340e',
  },
  [RESOURCES_WITH_RNET.kino]: {
    desktop: '421c25317769477587eac7934cb63a0b',
    mobile: '034ba64e2a924da5b92b61d851664286',
  },
  [RESOURCES_WITH_RNET.lenta1]: {
    desktop: 'e4b65a4e17124849a7be9ec18b529dcf',
    mobile: '3c3cd7ecb6ce4d8d9a16fd16d5676ed0',
  },
  [RESOURCES_WITH_RNET.lenta2]: {
    desktop: 'e4b65a4e17124849a7be9ec18b529dcf',
    mobile: '3c3cd7ecb6ce4d8d9a16fd16d5676ed0',
  },
  [RESOURCES_WITH_RNET.moslenta]: {
    desktop: 'e133899f656c4401a799d3b0b02cbfc7',
    mobile: '19c13766c00d43e5a1db73a658ed2ed1',
  },
  [RESOURCES_WITH_RNET.motor]: {
    desktop: '79fb3cdcf3d84243bcd0984d04bde83c',
    mobile: 'da701fe0667d497bbb7af88b9d2bc86a',
  },
  [RESOURCES_WITH_RNET.news]: {
    desktop: 'dae6c7033004426d8b0b47b4d8cdac2f',
    mobile: 'b02ecf5bfc38450081d5b0a090c51aa2',
  },
  [RESOURCES_WITH_RNET.passion]: {
    desktop: '73418228fba14a2e9017b36e08ef5a69',
    mobile: '0499871f90454d2f8ed241101abf6e14',
  },
  [RESOURCES_WITH_RNET.secretmag]: {
    desktop: '1f904a1d265741f7a42ea39057dcacc5',
    mobile: '89d41217e4184a2cb5930954c673d0b4',
  },
  [RESOURCES_WITH_RNET.sport]: {
    desktop: '27a0a21cff44448ea828f5fd4cfa9168',
    mobile: '178c7ffce1fe4798b90c9030b577d375',
  },
  [RESOURCES_WITH_RNET.travel]: {
    desktop: '0cb722a68ad640c1875dbae1ead0cf09',
    mobile: '4eb98ca006cf4b429db158aa0eb30c10',
  },
  [RESOURCES_WITH_RNET.weekend]: {
    desktop: 'f77f0f54f07e486a9de9b0bdab211805',
    mobile: 'e8f760457dce49b2ad660db3bdd5baae',
  },
  [RESOURCES_WITH_RNET.woman]: {
    desktop: '1609ba7bca9c4db295329b3ad60651ca',
    mobile: '845361ad52f6418b90e5eff635898f5b',
  },
};

export const BLOCK_ID_FOR_RESOURCES_REDESIGN: {
  [key in RESOURCES_WITH_RNET]: {
    desktop: string;
    mobile: string;
  };
} = {
  [RESOURCES_WITH_RNET.auto]: {
    desktop: '34ded2e2cf4142f893c2123b013ccc20',
    mobile: 'b65a7aaa8f8a47c1b165563b58ffcc8e',
  },
  [RESOURCES_WITH_RNET.doctor]: {
    desktop: '1f2e51b2b0f84b32934a8ada76c8d5e9',
    mobile: '6e438f3ade69495783ec4253cd3455c1',
  },
  [RESOURCES_WITH_RNET.ferra]: {
    desktop: '2778e2d8b5bb41e0951a5bdeb42f3172',
    mobile: '9d17bd27afc64867b959bffb11932cc4',
  },
  [RESOURCES_WITH_RNET.finance]: {
    desktop: '9cd193cb53ac4ae2a4fa85f45e5bd0a6',
    mobile: '729fcead90e542bfb01c6b1a2d7af701',
  },
  [RESOURCES_WITH_RNET.gazeta1]: {
    desktop: '81745cc45c82438e872dbe184c0d023d',
    mobile: '6f31a5bc8f37430092c1ffb8a32f415b',
  },
  [RESOURCES_WITH_RNET.gazeta2]: {
    desktop: '81745cc45c82438e872dbe184c0d023d',
    mobile: '6f31a5bc8f37430092c1ffb8a32f415b',
  },
  [RESOURCES_WITH_RNET.kino]: {
    desktop: '00cd6d26fdb846e5a8f6210606526932',
    mobile: '432166064ba14e0d86feda5741410618',
  },
  [RESOURCES_WITH_RNET.lenta1]: {
    desktop: '1ff2a44a0d124e6a90088912875c171d',
    mobile: 'cbdbccfcef0344eab48181a32a3b4f17',
  },
  [RESOURCES_WITH_RNET.lenta2]: {
    desktop: '1ff2a44a0d124e6a90088912875c171d',
    mobile: 'cbdbccfcef0344eab48181a32a3b4f17',
  },
  [RESOURCES_WITH_RNET.moslenta]: {
    desktop: '754c6a08516d47b1b1298cbb7461279f',
    mobile: '37197c042f3e4291a937473f104eab46',
  },
  [RESOURCES_WITH_RNET.motor]: {
    desktop: 'd2cb0d9041f04072825d1dadcb9a22b8',
    mobile: '6d842f11834243718efa73dd99a188b9',
  },
  [RESOURCES_WITH_RNET.news]: {
    desktop: '3b31c62f8565415da4bbfa9f4a8e2d1b',
    mobile: '0bddd81a47fa44d98f65e3a9cac5a895',
  },
  [RESOURCES_WITH_RNET.passion]: {
    desktop: '3007d4bbab1145b2a2fd56d89448d9fd',
    mobile: 'e244a44913c24f0e95ee2b5ec2916e92',
  },
  [RESOURCES_WITH_RNET.secretmag]: {
    desktop: '791a762da6964686ae0c785df32be310',
    mobile: 'bb8f98d9293b485cb557871856a99ad1',
  },
  [RESOURCES_WITH_RNET.sport]: {
    desktop: '68660976f65d4635bfb744010772c2b0',
    mobile: 'ee9138ad06534c3f898353a0537356d2',
  },
  [RESOURCES_WITH_RNET.travel]: {
    desktop: 'a4a7037ec1d94af9a50e3ac8117b07c6',
    mobile: '713e28e4f38a43b8a63ab717e8018483',
  },
  [RESOURCES_WITH_RNET.weekend]: {
    desktop: '969c91c68e0242779c6681c12f0910e6',
    mobile: '170d4d34d8614064aa6948dcb6717a3e',
  },
  [RESOURCES_WITH_RNET.woman]: {
    desktop: '7617e41489694ef883adea42f2293f9d',
    mobile: 'e86f70893dc84e7c92ca3a904f70f309',
  },
};
